import cx from 'classnames';
import srcIconArrowRight from '../../assets/icon-arrow-right.svg';
import srcIconAdd from '../../assets/icon-circle-add-solid.svg';
import srcBankDirectLogo from '../../assets/logo-smartpay-bank-direct.svg';
import usePaymentMethods from '../../hooks/use-payment-methods';
import { BANK_ACCOUNT_ID, Card } from '../../redux/payment-method';
import {
  ccImgs,
  testCardPurposes,
} from '../../screens/PaymentScreen/MethodOption';

import { Link } from '@reach/router';
import useAppSelector from '../../hooks/use-app-selector';
import useMaintenanceCheck from '../../hooks/use-maintenance-check';
import styles from './PaymentMethodModal.module.scss';

const bankFeatureDisabledMsg = (
  <>
    <h2>
      <img src={srcBankDirectLogo} alt="Smartpay Bank Direct" height={27} />
    </h2>
    <div className={cx('info-wrapper block gray', styles['info-block'])}>
      <h3>
        こちらのストアのお支払いには、クレジットカードのみがご利用いただけます。
      </h3>
    </div>
  </>
);

const bankDirectLimitExceededMsg = (
  <>
    <h2>
      <img src={srcBankDirectLogo} alt="Smartpay Bank Direct" height={27} />
    </h2>
    <div className={cx('info-wrapper block gray', styles['info-block'])}>
      <h3>即時口座引き落としはご利用いただけません。</h3>
      <p>
        1回の取引限度額を超えているため、今回のお支払いに Smartpay
        即時口座引き落とし（バンクダイレクト）はご利用いただけません。
      </p>
    </div>
  </>
);

const cardRequiredMsg = (
  <>
    <h2>
      <img src={srcBankDirectLogo} alt="Smartpay Bank Direct" height={27} />
    </h2>
    <div className={cx('info-wrapper block gray', styles['info-block'])}>
      <h3>クレジットカードの登録が必要です</h3>
      <p>
        Smartpay即時口座引き落とし (バンクダイレクト)
        のご利用には、予備の支払い方法として有効なクレジットカードを追加する必要があります。
      </p>
    </div>
  </>
);

// const appRequiredMsg = (
//   <>
//     <h2>
//       <img src={srcBankDirectLogo} alt="Smartpay Bank Direct" height={27} />
//     </h2>
//     <div className={styles.list}>
//       <div className={cx(styles.items, styles.compact)}>
//         {/* eslint-disable-next-line react/jsx-no-target-blank */}
//         <a
//           href="https://smartpay.co/consumer/#consumer-mobile-app-intro"
//           target="_blank"
//           className={cx(styles.item, styles.app)}
//         >
//           <span className={styles.lhs}>
//             <img src={srcConsumerAppLogo} alt="" width={48} height={48} />
//             アプリをダウンロード
//           </span>
//           <img src={srcIconArrowRight} width={24} height={24} alt="" />
//         </a>
//         <div className={cx('info-wrapper')}>
//           <p>銀行口座を追加する場合は、アプリをダウンロードしてください。</p>
//         </div>
//       </div>
//     </div>
//   </>
// );

const noNewRegisterMsg = (
  <>
    <h2>
      <img src={srcBankDirectLogo} alt="Smartpay Bank Direct" height={27} />
    </h2>
    <div className={cx('info-wrapper block gray', styles['info-block'])}>
      <h3>
        現在、Smartpay即時口座引き落とし(バンクダイレクト)の新規受付を中止しています。
      </h3>
      <p>
        お客様には大変ご迷惑をお掛けしますが、再開まで今しばらくお待ちください。再開の際はニュースルームにてお知らせ致します。
      </p>
    </div>
  </>
);

const BankDirect = ({ onSelect }: { onSelect: (method: string) => void }) => {
  const { cards, banks } = usePaymentMethods();
  const { isBankDirectFeatureEnabled } = useAppSelector(
    (state) => state.featureFlag
  );
  const bankDirectLimitExceeded = useAppSelector(
    (state) => state.misc.bankDirectLimitExceeded
  );
  const { checkBankDirectMaintenance } = useMaintenanceCheck();
  const bankDirectMaintenance = checkBankDirectMaintenance();

  if (!isBankDirectFeatureEnabled) {
    return bankFeatureDisabledMsg;
  }

  if (bankDirectLimitExceeded) {
    return bankDirectLimitExceededMsg;
  }

  if (cards.length === 0) {
    if (
      bankDirectMaintenance &&
      bankDirectMaintenance.isBankDirectUnderMaintenance
    ) {
      return (
        <>
          <h2>
            <img
              src={srcBankDirectLogo}
              alt="Smartpay Bank Direct"
              height={27}
            />
          </h2>
          <div className={cx('info-wrapper block gray', styles['info-block'])}>
            <h3>メンテナンスのお知らせ</h3>
            <p>{bankDirectMaintenance.jaMessage}</p>
          </div>
        </>
      );
    }

    return cardRequiredMsg;
  }

  return banks.length > 0 ? (
    <div className={styles.list}>
      <div className={styles.items}>
        <button
          type="button"
          id={BANK_ACCOUNT_ID}
          className={styles.item}
          onClick={() => onSelect(BANK_ACCOUNT_ID)}
        >
          <span className={styles.lhs}>
            <img src={srcBankDirectLogo} alt="" height={24} />
          </span>
          <img src={srcIconArrowRight} width={24} height={24} alt="" />
        </button>
      </div>
    </div>
  ) : (
    noNewRegisterMsg
  );
};

const MethodSelector = ({
  onSelect,
  onClickAddCard,
}: {
  onSelect: (method: string) => void;
  onClickAddCard: () => void;
}) => {
  const { isTestSession } = useAppSelector((state) => state.misc);

  const { cards } = usePaymentMethods();

  return (
    <div className={styles.kinds}>
      <h1>お支払い方法</h1>
      <div className={styles.kind}>
        <h2>クレジットカード</h2>
        <div className={styles.list}>
          {cards.length > 0 && (
            <div className={styles.items}>
              {cards.map((card: Card) => {
                return (
                  <button
                    type="button"
                    className={styles.item}
                    key={card.id}
                    onClick={() => onSelect(card.id)}
                  >
                    <span className={styles.lhs}>
                      <img
                        src={ccImgs[card.cardType]}
                        alt={card.cardType}
                        width={40}
                        height={24}
                      />
                      ••••{card.last4}
                    </span>
                    <span className={styles.rhs}>
                      {testCardPurposes[card.id] && (
                        <span
                          className={cx(
                            styles['test-label'],
                            testCardPurposes[card.id]
                          )}
                        >
                          {testCardPurposes[card.id].split('-').join(' ')}
                        </span>
                      )}
                      <img
                        src={srcIconArrowRight}
                        width={24}
                        height={24}
                        alt=""
                      />
                    </span>
                  </button>
                );
              })}
            </div>
          )}
          {!isTestSession && (
            <div className={cx(styles.items, styles['extra-items'])}>
              <div className={styles.item} onClick={onClickAddCard}>
                <span className={styles.lhs}>
                  <img
                    src={srcIconAdd}
                    className={styles['add-icon']}
                    width={32}
                    height={32}
                    alt=""
                  />
                  カードを登録する
                </span>
                <img src={srcIconArrowRight} width={24} height={24} alt="" />
              </div>
            </div>
          )}
        </div>
        <p className={styles.note}>
          注意：プリペイドカード、デビットカードはご利用いただけません。
          ※デフォルトのお支払い方法が一番上に表示されます。
        </p>
      </div>
      <div className={styles.kind}>
        <BankDirect onSelect={onSelect} />

        <p className={styles.note}>
          注意：Smartpay即時口座引き落とし
          (バンクダイレクト)は、1回の取引限度額が3万円で、本人確認後は6万円になります。一部の金融機関や加盟店では、ご利用限度額が異なる場合があります。
        </p>

        <div className={cx('info-wrapper block gray', styles['info-block'])}>
          <h3>お支払い方法について</h3>
          <ul>
            <li>
              デフォルトの支払方法で決済ができない場合、Smartpayが他の支払方法への請求を上から順に試みることを承認することになります。
              一番上のクレジットカードが最新で、十分な資金があることを確認してください。
            </li>
            <li>
              銀行口座を追加する前に、まず予備の支払い方法として有効なクレジットカードを追加する必要があります。
            </li>
          </ul>
          <p>
            詳しくは
            <Link to="/terms" className={styles.tnc}>
              利用規約
            </Link>
            と
            <Link to="/privacy" className={styles.tnc}>
              プライバシーポリシー
            </Link>
            をご覧ください。
          </p>
        </div>
      </div>
    </div>
  );
};

export default MethodSelector;
